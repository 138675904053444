import React, { useState } from "react";
import Swal from "sweetalert2";
import ServiceTime from "./ServiceTime";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { sendDiscord } from "../utils/modules";

// 데이터 분리
const CLEAN_TYPES = [
  "입주청소",
  "정기청소",
  "숙박청소 (10개 이상)",
  "사무실 / 기업청소",
  "건물청소",
];

const REQUEST_TYPES = ["정기구독", "일회성 청소"];

export default function CleanForm() {
  const open = useDaumPostcodePopup();
  const [policyCheck, setPolicyCheck] = useState(false);
  // 상태 관리
  const [formState, setFormState] = useState({
    cleanType: "",
    cleanRequest: "",
    address: "",
    detailAddress: "",
    area: "",
    name: "",
    contact: "",
    details: "",
  });

  const {
    cleanType,
    cleanRequest,
    address,
    detailAddress,
    area,
    name,
    contact,
    details,
  } = formState;

  const updateFormState = (key, value) => {
    setFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  // 주소 처리
  const handleComplete = (data) => {
    let fullAddress = data.address;
    if (data.addressType === "R") {
      const extraAddress = [data.bname, data.buildingName]
        .filter(Boolean)
        .join(", ");
      fullAddress += extraAddress ? ` (${extraAddress})` : "";
    }
    updateFormState("address", fullAddress);
  };

  const handleClick = () => open({ onComplete: handleComplete });

  const handlePolicy = () =>
    (window.location.href = "https://www.policy.bgogooma.com/policy");

  // 유효성 검사
  const validateForm = () => {
    const validations = [
      { field: cleanType, message: "청소 종류를 선택하세요" },
      { field: cleanRequest, message: "청소 요청을 선택하세요" },
      { field: address, message: "주소를 입력하세요" },
      { field: detailAddress, message: "상세 주소를 입력하세요" },
      { field: area, message: "평수를 입력하세요" },
      { field: name, message: "이름을 입력하세요" },
      { field: contact, message: "연락처를 입력하세요" },
    ];

    for (const { field, message } of validations) {
      if (!field) {
        Swal.fire({ text: message, icon: "info", confirmButtonText: "확인" });
        return false;
      }
    }
    return true;
  };

  // 제출 처리
  const handleSubmit = async () => {
    if (!validateForm()) return;

    const postData = {
      content: null,
      embeds: [
        {
          title: "\b청소 요청이 들어왔어요! 📩",
          color: 5814783,
          fields: [
            { name: "청소 종류 🧹", value: cleanType },
            { name: "청소 요청 📝", value: cleanRequest },
            { name: "주소 🏠", value: `${address} ${detailAddress}` },
            { name: "연락처 ☎️", value: `이름: ${name}\n전화번호: ${contact}` },
            {
              name: "요청사항💬",
              value: details || "요청사항이 없는 청소입니다",
            },
          ],
        },
      ],
      username: "헬퍼잇 청소 알림이",
      avatar_url:
        "https://k.kakaocdn.net/dn/bvGlAw/btsrTpmwBfF/iuy0PVbp94FmAsvjVQHQCk/img_xl.jpg",
    };

    if (policyCheck) {
      const response = await sendDiscord(postData);
      if (response.ok) {
        // 상태 초기화
        setFormState({
          cleanType: "",
          cleanRequest: "",
          address: "",
          detailAddress: "",
          area: "",
          cleanDate: "",
          name: "",
          contact: "",
          details: "",
        });
        Swal.fire({
          title: "신청이 완료되었어요!",
          text: "영업일 기준 2~3일 소요됩니다",
          icon: "success",
          confirmButtonText: "확인",
        });
      }
    } else {
      Swal.fire({
        text: "개인정보 처리방침에 동의해주세요",
        icon: "info",
        confirmButtonText: "확인",
      });
    }
  };

  return (
    <div id="clean-form">
      <div className="clean-section-in">
        <div className="title_box">
          <div className="title">
            <h1 className="title_p">
              아깝지 않은 합리적인 청소
              <br />
              무료 견적 신청하기
            </h1>
            <ServiceTime />
          </div>
        </div>
        <div className="clean_from_box">
          <ul>
            <li>청소 종류</li>
            <li>
              {CLEAN_TYPES.map((item, index) => (
                <span key={index}>
                  <input
                    type="radio"
                    name="radio01"
                    className="radio01"
                    checked={item === cleanType}
                    onChange={() => updateFormState("cleanType", item)}
                  />
                  {item}
                </span>
              ))}
            </li>
            <li>청소 요청</li>
            <li>
              {REQUEST_TYPES.map((item, index) => (
                <span key={index}>
                  <input
                    type="radio"
                    name="radio02"
                    className="radio01"
                    checked={item === cleanRequest}
                    onChange={() => updateFormState("cleanRequest", item)}
                  />
                  {item}
                </span>
              ))}
            </li>
            <li>연락 받으실 정보</li>
            <li>
              <input
                className="text01"
                type="text"
                name="name"
                placeholder="이름"
                value={name}
                onChange={(e) => updateFormState("name", e.target.value)}
              />
              <input
                className="text01"
                type="number"
                name="tel"
                placeholder="전화번호"
                value={contact}
                onChange={(e) => updateFormState("contact", e.target.value)}
              />
            </li>
            <li>청소를 진행 할 장소</li>
            <li>
              <input
                className="text01"
                type="text"
                name="adress"
                placeholder="주소"
                readOnly
                value={address}
                onClick={handleClick}
              />
              <input
                className="text01"
                type="text"
                name="detail_address"
                placeholder="상세주소"
                value={detailAddress}
                onChange={(e) =>
                  updateFormState("detailAddress", e.target.value)
                }
              />
              <input
                className="text01"
                type="number"
                name="area"
                placeholder="평수"
                value={area}
                onChange={(e) => updateFormState("area", e.target.value)}
              />
            </li>
            <li>요청사항</li>
            <li>
              <textarea
                className="text01"
                name="name"
                placeholder="요청사항을 입력해주세요"
                value={details}
                onChange={(e) => updateFormState("details", e.target.value)}
              />
            </li>
            <li>
              <div id="agreement">
                <div>
                  <input
                    type="checkbox"
                    id="privacy"
                    className="checkbox01"
                    style={{ marginRight: "10px" }}
                    value={policyCheck}
                    onClick={() => setPolicyCheck(!policyCheck)}
                  />
                  <p style={{ display: "inline-block", fontSize: "0.8em" }}>
                    [필수] 개인정보 수집 및 이용 동의
                  </p>
                </div>
                <span
                  onClick={handlePolicy}
                  style={{ cursor: "pointer", fontSize: "0.6em" }}
                >
                  ※ 약관보기
                </span>
              </div>
              <br />
              <div id="agreement">
                <div>
                  <input
                    type="checkbox"
                    id="privacy"
                    className="checkbox01"
                    style={{ marginRight: "10px" }}
                  />
                  <p style={{ display: "inline-block", fontSize: "0.8em" }}>
                    [선택] 마케팅 활용 동의
                  </p>
                </div>
                <span style={{ cursor: "pointer", fontSize: "0.6em" }}>
                  ※ 약관보기
                </span>
              </div>
            </li>
          </ul>
          <button className="button_01 button" onClick={handleSubmit}>
            문의하기
          </button>
        </div>
      </div>
    </div>
  );
}
