import React from "react";
import Slider from "react-slick";
import ReviewBox from "./ReviewBox";
import clean1 from "../assets/images/clean/clean1.jpeg";
import clean2 from "../assets/images/clean/clean2.jpeg";
import clean3 from "../assets/images/clean/clean3.jpeg";
import clean4 from "../assets/images/clean/clean4.jpeg";
import clean6 from "../assets/images/clean/clean6.jpeg";
import clean7 from "../assets/images/clean/clean7.jpeg";
import clean8 from "../assets/images/clean/clean8.jpeg";
import clean9 from "../assets/images/clean/clean9.jpeg";
import clean10 from "../assets/images/clean/clean10.jpeg";
import clean11 from "../assets/images/clean/clean11.jpeg";

const settings = {
  slidesToShow: 5,
  slidesToScroll: 1,
  centerMode: true,
  autoplay: true,
  autoplaySpeed: 1500,
  dots: false,
  arrows: false,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const reviewData = [
  {
    title: "깨끗해진 침대 좀 보세요!",
    score: "5.0",
    image: clean1,
    name: "김**",
    desc: "바쁜 일상으로 방 청소를 하지 못했는데, '헬퍼잇 클린' 덕분에 금방 깨끗해졌네요. 정말 만족스러웠어요!",
  },
  {
    title: "갑작스러운 상황에도 딱입니다!",
    score: "5.0",
    image: clean2,
    name: "박**",
    desc: "설거지가 쌓였는데, '헬퍼잇 클린'으로 바로 도움을 받을 수 있었어요. 시간도 절약되고 스트레스도 줄었어요.",
  },
  {
    title: "변기 문제도 단반에 해결!",
    score: "5.0",
    image: clean3,
    name: "이**",
    desc: "'헬퍼잇 클린'으로 막힌 변기까지 한번에 해결되었어요. 서비스 품질도 좋아서 자주 이용하고 있습니다.",
  },
  {
    title: "청소 스트레스가 줄었어요.",
    score: "4.8",
    image: clean4,
    name: "정**",
    desc: "급하게 청소가 필요할 때 '헬퍼잇 클린'만한 서비스는 없어요. 너무 편리합니다.",
  },
  {
    title: "이용할수록 만족도가 높아지는 서비스!",
    score: "5.0",
    image: clean11,
    name: "최**",
    desc: "'헬퍼잇 클린' 덕분에 일손이 부족한 날에도 걱정이 없어요. 강추!",
  },
  {
    title: "이사하는 날에 청소가 고민이신가요?",
    score: "5.0",
    image: clean6,
    name: "손**",
    desc: "이사하는 날엔 정말 필수입니다. '헬퍼잇 클린'을 통해 빠르게 도움을 받을 수 있어요.",
  },
  {
    title: "시간 절약에 최고예요!",
    score: "5.0",
    image: clean7,
    name: "류**",
    desc: "바쁜 일상에서도 '헬퍼잇 클린'이 있어서 든든합니다. 믿을 수 있는 분들이 와서 만족도가 높아요.",
  },
  {
    title: "이만한 서비스 없습니다.",
    score: "4.9",
    image: clean8,
    name: "장**",
    desc: "이사하는 날에도 '헬퍼잇 클린'이 있어 걱정이 줄었어요. 입주청소를 합리적인 비용으로 쉽게 이용할 수 있습니다.",
  },
  {
    title: "스트레스 없는 청소!",
    score: "5.0",
    image: clean9,
    name: "문**",
    desc: "'헬퍼잇 클린' 덕분에 바쁜 날에도 청소와 뒷정리를 걱정할 필요가 없어졌어요. 정말 감사한 서비스입니다.",
  },
  {
    title: "혼자 사는 사람들에게 강력 추천!",
    score: "5.0",
    image: clean10,
    name: "오**",
    desc: "설거지, 청소 등 갑작스러운 일이 생길 때마다 '헬퍼잇 클린'이 해결해줬습니다. 꼭 사용해보세요!",
  },
];

export default function Review() {
  return (
    <div className="review-container" data-aos="fade-up" data-aos-delay="100">
      <div className="title">차원이 다른 청소를 경험해보세요</div>

      <Slider {...settings}>
        {reviewData.map((item, index) => (
          <ReviewBox
            key={index}
            title={item.title}
            score={item.score}
            image={item.image}
            name={item.name}
            desc={item.desc}
          />
        ))}
      </Slider>
    </div>
  );
}
