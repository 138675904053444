import React from "react";

import clean_icon_pay from "../assets/images/clean_icon_pay.svg";
import profile from "../assets/images/profile.png";

export default function Section5() {
  return (
    <div id="clean-section-5">
      <div className="clean-section-in">
        <div className="title_box">
          <div className="title" data-aos="fade-up" data-aos-offset="100">
            <h1 className="title_p" data-aos="fade-up" data-aos-offset="100">
              차별화된
              <br />
              <span className="color_lime">맞춤형</span>
              <br />
              청소 케어
            </h1>
          </div>
          <div className="service_box" data-aos="fade-up" data-aos-offset="100">
            <div>
              <h1>
                01
                <br />
                기본 청소 제공
                <br />
              </h1>
              <h2>
                입주청소
                <br />
                건물청소
                <br />
                정기청소
                <br />
                숙박청소
                <br />
                (숙박은 30개호실 이상)
              </h2>
            </div>
            <div className="service_plus">
              <h1>+</h1>
            </div>
            <div>
              <h1>
                02
                <br />
                추가 청소 제공
                <br />
              </h1>
              <h2>
                원하는 사항이 있다면
                <br />
                맞춤 케어로 계약 진행
              </h2>
            </div>
            <div>
              <h1>
                <img src={clean_icon_pay} alt="카톡상담 화면" /> <br />
                유료 청소 제공
                <br />
              </h1>
              <h2>
                코로나19 바이러스 제거 방역 진행
                <br />
                (평수에 따라 가격 상이)
              </h2>
            </div>
          </div>
        </div>
        <div className="profile" data-aos="fade-up" data-aos-offset="100">
          <div className="profile-section-in">
            <h1 className="title">인사말</h1>
            <div className="profile-img">
              <img src={profile} alt="프로필 사진" />
            </div>
            <h3 className="name">박승호 이사 / 청소 경력 13년</h3>
          </div>
          <div className="desc">
            고객님께서 원하는 청소 퀄리티로 보답하겠습니다.
            <br />
            헬퍼잇 클린은 전문 인력 양성으로
            <br />
            고객님께 만족을 드리며 다가가겠습니다
            <br />
            <br />
            감사합니다.
          </div>
        </div>
      </div>
    </div>
  );
}
