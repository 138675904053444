import React from "react";

import clean_s3_img01 from "../assets/images/clean_s3_img01.svg";
import h_arrow from "../assets/images/h_arrow.svg";
import v_arrow from "../assets/images/v_arrow.svg";

import step1 from "../assets/images/step1.svg";
import step2 from "../assets/images/step2.svg";
import step3 from "../assets/images/step3.svg";

export default function Section3() {
  return (
    <div id="clean-section-3">
      <div className="clean-section-in">
        <div className="title_box">
          <div className="title" data-aos="fade-up" data-aos-offset="100">
            <h1 className="title_p" data-aos="fade-up" data-aos-offset="100">
              사업장을
              <br />
              운영하시나요?
              <br />
              <span className="color_lime">
                청소 및 관리는 헬퍼잇클린에서
                <br />
                가장 완벽하게
              </span>
            </h1>
            <h2>헬퍼잇 청소 정기구독 진행 프로세스</h2>
          </div>
          <div className="img_box">
            <img src={clean_s3_img01} alt="현장사진1" />
          </div>
        </div>
        <div className="step_box">
          <div className="step_list">
            <img src={step1} alt="상담 및 계약" />
            문의하기
          </div>
          <div className="h_arrow">
            <img src={h_arrow} alt="화살표" />
          </div>
          <div className="v_arrow">
            <img src={v_arrow} alt="화살표" />
          </div>
          <div className="step_list">
            <img src={step2} alt="맞춤교육" />
            상담 / 계약 진행
          </div>
          <div className="h_arrow">
            <img src={h_arrow} alt="화살표" />
          </div>
          <div className="v_arrow">
            <img src={v_arrow} alt="화살표" />
          </div>
          <div className="step_list">
            <img src={step3} alt="고정 헬퍼 매칭" />
            정기청소 진행
          </div>
        </div>
      </div>
    </div>
  );
}
