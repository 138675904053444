import React from "react";
import "../assets/css/footer.css";
import logo from "../assets/images/logo.svg";
import Fmail from "../assets/images/Fmail.svg";
import Ffacebook from "../assets/images/Ffacebook.svg";
import Finstagram from "../assets/images/Finstagram.svg";
import Fyoutube from "../assets/images/Fyoutube.svg";

import ScrollToTop from "./ScrollToTop";

export default function Footer() {
  return (
    <div id={"footer"}>
      <div className="footer-in">
        <div className="section-2">
          <div>
            <p className="f-logo">
              <a href={"/"}>
                <img src={logo} alt="헬퍼잇 로고" />
              </a>
            </p>
          </div>
          <div className="futill">
            <p>
              <a href={"https://www.policy.bgogooma.com/service"}>이용약관</a>
            </p>
            <p>
              <a href={"https://www.policy.bgogooma.com/policy"}>
                개인정보처리방침
              </a>
            </p>
            <ul className="fnb">
              <li>
                상호 : <span>주식회사 불타는고구마</span>
                &nbsp;&nbsp;대표이사 : <span>최석현</span>
                <br />
                개인정보관리책임자 : <span>박창현</span>
                <br />
                이메일 : <span>backend2374@bgogooma.com</span>
              </li>
              <li>
                사업자등록번호 : <span>803-87-02223</span> <br /> 통신판매 :
                <span> 제 2021-부산수영-0894 호</span>
              </li>
              <br />
              <li>
                <span>부산광역시 해운대구 삼어로 207-1 3층</span>
                <br />
                대표전화번호 : <span>1800-9323</span>
              </li>
            </ul>
            <small className="fcopy">
              Copyright ⓒ 2021 (주)불타는 고구마 All Rights Reserved.
            </small>
          </div>
          <div className={"fapp"}>
            <p>
              <a
                href="mailto:csh@bgogooma.com"
                target="_blank"
                className="mail"
              >
                <img src={Fmail} alt="이메일" />
              </a>
            </p>
            <p>
              <a href={"https://www.facebook.com/helperit1/?locale=ko_KR"}>
                <img src={Ffacebook} alt="페이스북" />
              </a>
            </p>
            <p>
              <a
                href="https://www.instagram.com/p/CToV6ZkhRwT/?utm_medium=copy_link"
                target="_blank"
                className="instagram"
              >
                <img src={Finstagram} alt="인스타그램" />
              </a>
            </p>
            <p>
              <a href={"https://www.youtube.com/@%ED%97%AC%ED%8D%BC%EC%9E%87"}>
                <img src={Fyoutube} alt="유튜브" />
              </a>
            </p>
            {/* 최상단 스크롤 버튼 */}
            <ScrollToTop />
          </div>
        </div>
      </div>
    </div>
  );
}
