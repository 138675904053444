import React from "react";

export default function ServiceTime() {
  return (
    <div
      style={{
        fontSize: 18,
        textAlign: "center",
        marginTop: 18,
        marginBottom: 18,
      }}
    >
      <div>* 현재 서비스 가능 지역 - 부산</div>
      <div style={{ marginTop: 10 }}>상담 시간 평일 09:00 ~ 18:00</div>
    </div>
  );
}
