export async function sendDiscord(params) {
  try {
    return await fetch(
      `https://discord.com/api/webhooks/${process.env.REACT_APP_WEBHOOK_ID}/${process.env.REACT_APP_WEBHOOK_TOKEN}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      }
    );
  } catch (err) {
    throw new Error(err.message);
  }
}
