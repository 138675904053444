import React from "react";
import Slider from "react-slick";
import "../assets/css/review.css";
import "../assets/css/service.css";
import "../assets/css/certificate.css";
import cert1 from "../assets/images/cert1.jpg";
import cert2 from "../assets/images/cert2.jpg";
import cert3 from "../assets/images/cert3.jpg";
import cert4 from "../assets/images/cert4.jpg";
import patent1 from "../assets/images/patent1.jpg";
import patent2 from "../assets/images/patent2.jpg";
import certificate from "../assets/images/service.png";

const certs = [certificate, cert1, cert2, cert3, cert4, patent1, patent2];

const settings = {
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: true,
  autoplay: true,
  autoplaySpeed: 1500,
  dots: false,
  arrows: false,
  initialSlide: 2,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function CertificateBox({ image }) {
  return (
    <div className="frame" style={{ margin: "1em" }}>
      <img src={image} className="certificate content" alt="신분증" />
    </div>
  );
}

export default function Service() {
  return (
    <div id="section-2">
      <div className="section-in">
        <h4 className="text-center">
          소중한 시간을 어떻게 하면 효율적으로 사용할 수 있을까?
        </h4>
        <h5 className="text-center">
          헬퍼잇 클린은 전문 청소 서비스와 체계적인 관리로 고객님의 소중한
          시간을 아껴드립니다
        </h5>
        <Slider {...settings}>
          {certs.map((item, index) => (
            <CertificateBox key={index} image={item} />
          ))}
        </Slider>
      </div>
    </div>
  );
}
