import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import clean12 from "../assets/images/clean/clean12.jpeg";
import clean13 from "../assets/images/clean/clean13.jpeg";
import clean14 from "../assets/images/clean/clean14.jpeg";
import clean15 from "../assets/images/clean/clean15.jpeg";

export default function Section2() {
  return (
    <div id="clean-section-2">
      <div className="clean-section-in">
        <div className="title_box">
          <div className="title" data-aos="fade-up" data-aos-offset="100">
            <h2>이사를 준비중이신가요?</h2>
            <h2>정기 청소 관리에 최적화된 청소 시스템</h2>
            <h2>헬퍼잇 클린이 인정한 믿음가는 청소인력</h2>
            <h2>고정적인 청소인력으로 신뢰하는 서비스</h2>
            <h1 className="title_p" data-aos="fade-up" data-aos-offset="100">
              월 수행 청소
              <br />
              평균 약<br />
              <span className="color_lime">2,200건</span>
            </h1>
            <h3>2023.12 ~ 2024. 6 기준</h3>
          </div>
          <div className="img_box">
            <ul>
              <li data-aos="fade" data-aos-offset="300">
                <img src={clean12} alt="현장사진1" />
              </li>
              <li data-aos="fade" data-aos-offset="300">
                <img src={clean13} alt="현장사진2" />
              </li>
              <li data-aos="fade" data-aos-offset="200">
                <img src={clean14} alt="현장사진3" />
              </li>
              <li data-aos="fade" data-aos-offset="200">
                <img src={clean15} alt="현장사진4" />
              </li>
            </ul>
          </div>
        </div>
        <div className="btn_box">
          <ul>
            <li>
              <span className="color_lime">고정 청소 </span>인력 배치
            </li>
            <li>
              <span className="color_lime">1년 365일 </span> 연중무휴
            </li>
            <li>
              <span className="color_lime">쉽고 간편한 </span>청소 관리
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
