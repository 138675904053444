import React from "react";

import logo from "../assets/images/logo.svg";

export default function Menu(props) {
  const { open } = props;

  if (open) {
    return (
      <div className="m-overlay">
        <div className="all-gnb">
          <div className="all-navi-inner">
            <div className="all-in">
              <ul>
                <li>
                  <a href={"/"}>
                    <img src={logo} alt="헬퍼잇 로고" />
                  </a>
                </li>
                <li>
                  <a href="https://helperit.co.kr">심부름이 필요할땐?</a>
                </li>
              </ul>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div className="Nfcopy">
              Copyright ⓒ 2021 (주)불타는 고구마 All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    );
  }
}
