import React, { useEffect } from "react";

import top_btn from "../assets/images/top_btn.svg";
import kakao_btn from "../assets/images/kakao_btn.svg";

export default function ScrollToTop() {
  const onClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.onscroll = function () {
      if (
        document.body.scrollTop > 90 ||
        document.documentElement.scrollTop > 90
      ) {
        document.getElementById("show").className = "go-top";
      } else {
        document.getElementById("show").className = "go-top-hide";
      }
    };
  }, []);

  return (
    <>
      <a href="https://pf.kakao.com/_meRVG" target="_black" className="kakao">
        <img src={kakao_btn} alt="카카오" />
      </a>
      <div className={"topbtn"}>
        <div id="show" className="go-top-hide" onClick={onClick}>
          <img src={top_btn} alt="홈페이지 상단으로 가기" />
        </div>
      </div>
    </>
  );
}
