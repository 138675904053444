import React from "react";

import recycle_mark from "../assets/images/recycle_mark.svg";
import recycle_tel from "../assets/images/recycle_tel.svg";
import recycle_kakao from "../assets/images/recycle_kakao.svg";

export default function Recycle() {
  return (
    <div id="recycle-section-5">
      <div className="recycle-section-in">
        <p>서비스 지역</p>
        <br />
        <img src={recycle_mark} data-aos="fade-up" alt="위치" />
        <br />
        현재 가능 지역
        <br />
        부산 전지역
        <br />
        <br />
        <br />
        <br />
        <strong>Coming soon</strong>
        <br />
        전국 서비스 확대
        <br />
        <br />
        <ul>
          <li>
            <img src={recycle_tel} alt="전화기" />
            고객센터 1800-9323
          </li>
          <li>
            <img src={recycle_kakao} alt="카카오" />
            카카오채널 @헬퍼잇_청소 전용
          </li>
        </ul>
      </div>
    </div>
  );
}
