import React from "react";

export default function Esg() {
  return (
    <div id="esg-section">
      <div className="esg-section-in">
        <div className="title_box" data-aos="fade-up" data-aos-offset="100">
          <div className="title" data-aos="fade-up" data-aos-offset="100">
            <h2>Environment Social Governance</h2>
            <h3>Waste Tech 기업 헬퍼잇</h3>
            <h1 className="title_p" data-aos="fade-up" data-aos-offset="100">
              E S G
            </h1>
            <h2>
              불편함은 줄이고 재능은 높이고
              <br />
              헬퍼잇은 헬퍼분의 재능을 믿습니다.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}
