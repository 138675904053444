import "../src/assets/css/reset.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "./components/Navbar";
import Main from "./components/Main";

AOS.init({
  duration: 1000,
});

function App() {
  return (
    <div>
      <Navbar />
      <Main />
    </div>
  );
}

export default App;
