import React from "react";

import "../assets/css/review.css";

export default function ReviewBox({ title, score, name, image, desc }) {
  return (
    <div className="reviewbox">
      <div className="title">{title}</div>
      <div className="infoline">
        <div className="score">{score}</div>
        <div className="name">{name}</div>
      </div>
      <img src={image} className="review-image" />
      <p className="desc">{desc}</p>
    </div>
  );
}
