import React, { useEffect, useState } from "react";
import "../assets/css/navbar.css";
import logo from "../assets/images/logo.svg";
import Sticky from "react-sticky-el";
import MenuButton from "./MenuButton";
import Menu from "./Menu";

const styles = {
  unscroll: {
    backgroundColor: "white",
  },
  scroll: {
    backgroundColor: "transparent",
    borderBottom: "none",
    boxShadow: "none",
  },
  s_text: {
    color: "white",
  },
  us_text: {
    color: "black",
  },
};

export default function Navbar() {
  const [isScroll, setIsScroll] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);

  const scrollPosition = () => {
    setIsScroll(
      window.scrollY > 200 || document.documentElement.scrollTop > 200
        ? false
        : true
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollPosition);
  }, []);

  return (
    <Sticky className="sticky-wrap">
      <div id="header" style={isScroll ? styles.scroll : styles.unscroll}>
        <div className="header-in">
          <div className="logo">
            <a href={"/"}>
              <img src={logo} height="36px" alt="스마트안전 로고" />
            </a>
          </div>
          <ul className="gnb" style={{ color: "black" }}>
            <li>
              <a href="https://helperit.co.kr/" style={styles.us_text}>
                심부름이 필요할땐?
              </a>
            </li>
          </ul>
          <div className="menu-wrap">
            <MenuButton
              open={menuOpen}
              onClick={() => setMenuOpen(!menuOpen)}
              color="black"
            />
          </div>
        </div>
      </div>
      <Menu open={menuOpen}></Menu>
    </Sticky>
  );
}
